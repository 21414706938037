import * as Yup from 'yup'

export const MemberProfileUpdateSchemasGeneric = Yup.object().shape({
  firstName: Yup.string().required('First name is required.').nullable(),
  lastName: Yup.string().required('Last name is required.').nullable(),
  birthDate: Yup.string().nullable(),
  // birthDate: Yup.string().nullable(),
  genderId: Yup.string().required('Gender is Required.').nullable(),
  email: Yup.string().nullable(),
  // mobile: Yup.string().required('Mobile is Required.').nullable(),
  mobile: Yup.string().nullable(),
  enrollmentTraderId: Yup.string().nullable(),
  // address1: Yup.string().required('Address is Required.').nullable(),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
  suburb: Yup.string().nullable(),
  // state: Yup.string().required('State is Required.').nullable(),
  state: Yup.string().nullable(),
  // city: Yup.string().required('City is Required.').nullable(),
  city: Yup.string().nullable(),
  // postCode: Yup.string().required('Postal Code is Required.').nullable(),
  postCode: Yup.string().nullable(),
  // country: Yup.string().required('Country is Required.').nullable(),
  country: Yup.string().nullable(),
})
