import React from 'react'

import { MerchantLookupTxSetFieldTypes } from 'types/merchanTxTypes'
import Form from './Form'

const Index: React.FC<{
  isMerchantLookupToggle: boolean
  setFieldValue: MerchantLookupTxSetFieldTypes
  title?: string;
}> = ({ isMerchantLookupToggle, setFieldValue, title }): React.ReactElement => {
  if (!isMerchantLookupToggle) return <></>
  return <Form setFieldValue={setFieldValue} title={title}/>
}

export default Index
